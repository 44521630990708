<h1 class="banner-content " *ngIf="BannerHeading">{{BannerHeading}}</h1>
<div class="banner-about-content">
    <p>
        Leprosy is a curable infectious disease. The causative agent, <i>Mycobacterium leprae</i>, has the unique
        capability to infect peripheral nerves, which may lead to varying degrees of neuropathy. In the most severe
        circumstances, this infection may result in an inability to feel pain in the hands or feet, a loss of digits,
        and blindness.
    </p>
    <p>
        Paradoxically, as treatment has become more successful, many specialized centers have closed and few physicians
        have adequate knowledge about the disease. A strong stigma, based on fear, still contributes to prolonged
        suffering and delayed diagnosis. Today, the diagnosis and treatment of leprosy also is often impeded by a lack
        of knowledge and awareness in the healthcare community. This textbook attempts to address the deficiency.
    </p>
    <p>
        The <i>International Textbook of Leprosy</i> is dedicated to the physicians and health workers caring for their
        first patient with leprosy, and to all of those in the research community who have encountered some of the
        fascinating scientific aspects of leprosy and wish to learn more.
    </p>
    <p><a href="" class="editor">— David M Scollard and Thomas P Gillis</a>, Editors</p>
    <hr class="mb-0">
    <div class="d-flex">
        <fa-icon [icon]="['fas','quote-left']" class="citation-icon"></fa-icon>
        <h5 class="citation-text">Citation</h5>
    </div>
    <p>Scollard, DM, & Gillis, TP, Eds. (2016) International Textbook of Leprosy. American Leprosy Missions, Greenville,
        SC. <a href="">https://doi.org/10.1489/itl</a></p>
    <div class="search mt-1">
        <div class="search-container">
            <h5 class="pb-3">Explore chapters</h5>
            <app-search (searchEvent)="onSearchEvent($event)" (emitCheck)="emitSearch($event)"
            (searchClick)="showSearchList($event)" (clearEmit)="clearEmited($event)"></app-search>
        </div>
    </div>
</div>