import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, Inject, PLATFORM_ID, output } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
declare var bootstrap: any;

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrl: './home-banner.component.scss'
})
export class HomeBannerComponent implements OnInit, AfterViewInit {
  @Input() pageType = '';
  @Input() BannerHeading = '';
  @Input() BannerImage = '';
  @ViewChild('bannerCarousel') bannerCarousel: ElementRef<HTMLElement>;
  BannerHeadingfirstPart = '';
  BannerHeadinglastPart = '';
  contentHeight = '';
  searchVAlue = '';

  @Output() searchEvent = new EventEmitter<string>();
  @Output() emitFilter = new EventEmitter<string[]>();

  categories: any[] = [];
  categoryTitles: string[] = [];
  category: string = '';
  categoryTitle: string[] = [];
  CategoryarrayForListing: string[] = [];
  categoryContent: any;
  pageloading = true;
  carouselHeight = 0;
  search_filter: string[] = [];
  constructor(
    private articleService: ArticleService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      // this.screenWidth = event.target.window.innerWidth;
      this.carouselHeight = this.bannerCarousel?.nativeElement.offsetHeight;
    }
  }

  ngOnInit(): void {
    this.splitText()
    if (this.BannerImage) {
      this.pageloading = false;
    }
  }

  ngAfterViewInit(): void {
    if (this.bannerCarousel) {
      this.carouselHeight = this.bannerCarousel.nativeElement.offsetHeight;
      const myCarouselElement = this.bannerCarousel.nativeElement;
      const carousel = new bootstrap.Carousel(myCarouselElement, {
        interval: 4000,
        touch: false
      });
    }
  }

  splitText() {
    const words = this.BannerHeading.split(' ');
    if (words.length > 1) {
      this.BannerHeadingfirstPart = words.slice(0, -1).join(' ');
      this.BannerHeadinglastPart = words[words.length - 1];
    } else {
      this.BannerHeadingfirstPart = this.BannerHeading;
      this.BannerHeadinglastPart = '';
    }
  }

  onSearchEvent(searchValue: string) {
    this.searchEvent.emit(searchValue);
    this.searchVAlue = searchValue;
  }

  emitSearch(search_filter: string[]) {
    this.emitFilter.emit(search_filter);
    this.search_filter = search_filter;
  }

  showSearchList(click: boolean) {
    const queryParams: any = {};
  
    // Add query parameters conditionally
    if (this.searchVAlue && this.searchVAlue.trim() !== '') {
      queryParams['searchValue'] = this.searchVAlue;
    }
    if (this.search_filter && this.search_filter.length > 0) {
      queryParams['filter'] = this.search_filter.toString();
    }
  
    // Navigate only if queryParams is not empty
    if (Object.keys(queryParams).length > 0) {
      this.router.navigate(['/search'], { queryParams });
    } else {
      this.router.navigate(['/search']);
    }
  }
  
  clearEmited(clear:boolean){
    this.searchVAlue = '';
    this.search_filter = [];
    this.emitFilter.emit(this.search_filter);
  }

}
