import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlightSearch'
})
export class HighlightSearchPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, searchTerm: string): SafeHtml {
    if (!value) {
      return this.sanitizer.bypassSecurityTrustHtml('');
    }
    if (!searchTerm) {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    // Escape special characters in searchTerm
    const escapedSearchTerm = searchTerm.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

    const regex = new RegExp(escapedSearchTerm, 'gi');
    const highlightedValue = value.replace(regex, (match) => 
      `<span style='background-color: yellow;'>${match}</span>`
    );

    return this.sanitizer.bypassSecurityTrustHtml(highlightedValue);
  }
}
