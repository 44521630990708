<form [formGroup]="searchForm">
	<div class="search-section">
		<div class="row " #searchFormDiv>
			<div class="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-10 col-xs-10 p-0">
				<div class="input-group input-group-lg position-relative">
					<button class="input-group-text search-text-box" id="basic-addon1">
						<fa-icon [icon]="['fas','magnifying-glass']" class="search-icon"></fa-icon>
					</button>
	
					<input type="text" class="form-control search-form-control" placeholder="Search..." aria-label="Search"
						aria-describedby="basic-addon1" formControlName="searchValue" (keyup.enter)="onEnterPressed($event)"
						(input)="showSearchSuggestions($event)" (click)="onTextboxClicked()" />
					<!-- Loader -->
	
					<div *ngIf="ShowTextLoader==true" class="spinner-container" [ngStyle]="{'right':(clearBtnWidth)+'px'}">
						<div class="spinner-border" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
	
					<!-- Clear Button -->
					<button *ngIf="search && searchForm.get('searchValue')?.value.length > 0" type="button" class="clear-button" (click)="clearSearch()" #clearBtn>
						<fa-icon [icon]="['fas', 'eraser']" class="search-button-icon "></fa-icon>
						<span clas="clear-button-icon">Clear</span>
					</button>
				</div>
	
	
	
				<div *ngIf="showSearchSuggesion && articles.length > 0 && searchForm.get('searchValue')?.value.length > 0"
					class="search-result-section" [ngStyle]="{'width': resultBoxwidth + 'px'}">
					<div>
						<ul class="p-0">
							<li *ngFor="let result of articles; let isLast = last" class="search-result-li">
								<div class="d-flex flex-column custom-margin">
									<a *ngIf="result?.article_title" class="search_link"
										[routerLink]="['/',result.article_category.heading.id,result.article_category.part.id, result.article_link]">
										<p class="ml-3 search-title"
											[innerHTML]="highlightMatch(result.article_title, searchForm.get('searchValue')?.value)">
										</p>
									</a>
									<a *ngIf="result?.title" class="search_link"
										[routerLink]="['/page/',result?.identifier]">
										<p class="ml-3 search-title"
											[innerHTML]="highlightMatch(result.title, searchForm.get('searchValue')?.value)">
										</p>
									</a>
									<p *ngIf="result?.authors" class="ml-3 search-author" [ngStyle]="{'font-size': '14px'}"
										[innerHTML]="highlightMatch(formatAuthors(result.authors), searchForm.get('searchValue')?.value)">
									</p>
									<p *ngIf="result?.article_content" class="ml-3 search-content"
										[innerHtml]="result.article_content | highlightSearch:searchForm.get('searchValue')?.value">
									</p>
									<p *ngIf="result?.content" class="ml-3 search-content"
										[innerHtml]="result.content | highlightSearch:searchForm.get('searchValue')?.value">
									</p>
									<p *ngIf="result?.doi" class="ml-3 search-doi">
										<!-- <fa-icon [icon]="['fas','calendar-days']"
											class="icon-date pe-2"></fa-icon>
										<span [innerHTML]="result.published_online"></span> -->
										<fa-icon [icon]="['fas','earth-americas']" class="icon-date pe-2"></fa-icon>
										<a href={{result.doi}}
											[innerHtml]="highlightMatch(result.doi, searchForm.get('searchValue')?.value)"
											target="_blank"></a>
									</p>
									<!-- <p class="ml-3 search-pub-date">
										<fa-icon [icon]="['fas','calendar-days']" class="icon-date pe-2"></fa-icon>
										<span [innerHTML]="result.published_online"></span>
									</p> -->
									<!-- <div class="ml-3 card-tag">
										<a href="#" class="tag-content badge">{{ result.surgical_speciality }}</a>
										<a href="#" class="tag-content badge">{{ result.content_type }}</a>
									</div> -->
								</div>
								<!-- <hr *ngIf="!isLast" class="search-result-divider"> -->
							</li>
						</ul>
					</div>
				</div>

				<div *ngIf="articles.length === 0 && searchForm.get('searchValue')?.value?.length > 3 && showSearchSuggesion"
					class="search-result-section" [ngStyle]="{'width': resultBoxwidth + 'px'}">
					<div>
						<ul class="p-0">
							<li class="search-result-li">
								<div class="d-flex justify-content-center">
									<p class="mt-3">No results found</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<!-- <div *ngIf="!ShowTextLoader && searchResults.length === 0 && searchForm.get('searchValue')?.value?.length === 0" class="search-result-section">
			<ul class="p-0">
			  <li class="search-result-li">
				<div class="d-flex justify-content-center">
				  <p class="mt-3">No results found</p>
				</div>
			  </li>
			</ul>
		  </div> -->
	
	
				<!-- <div *ngIf="!ShowTextLoader && searchResults.length === 0 && searchForm.get('searchValue')?.value?.length ==0" class="search-result-section">
			<ul class="p-0">
			  <li class="search-result-li">
				<div class="d-flex justify-content-center">
				  <p class="mt-3">No results found</p>
				</div>
			  </li>
			</ul>
		  </div> -->
			</div>
			<div class="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-2 col-xs-2 p-0 search-button-container">
				<!-- <div class="d-flex flex-row"> -->
	
				<button type="button" class="btn btn-primary btn-lg search-button mt-0" (click)="searchArticles($event)">
					<fa-icon [icon]="['fas', 'magnifying-glass']" class="search-button-icon"></fa-icon>
					<span class="d-xs-none  d-sm-none d-md-inline custom-search ps-1">Search</span>
				</button>
				<!-- </div> -->
			</div>
		</div>
		<!-- <form> -->
		<div class="row mt-2">
			<div class="col filter-div-1 d-flex justify-content-start align-items-center pb-2 ps-0" #showref>
				<div class="form-check black-label ">
					<input style="cursor:pointer;" type="checkbox" class="form-check-input" id="check1" name="option1"
						(keyup.enter)="checkBoxChange($event,'title')" (change)="checkBoxChange($event,'title')"
						value="title" [checked]="uncheck['title']">
					<label class="form-check-label" for="check1">Chapter title</label>
				</div>
				<div class="form-check black-label ">
					<input style="cursor:pointer;" type="checkbox" class="form-check-input" id="check1" name="option2"
						(keyup.enter)="checkBoxChange($event,'author')" (change)="checkBoxChange($event,'author')"
						value="author" [checked]="uncheck['author']">
					<label class="form-check-label" for="check1">Author</label>
				</div>
				<div class="form-check black-label">
					<input style="cursor:pointer;" type="checkbox" class="form-check-input" id="check2" name="option3"
						(keyup.enter)="checkBoxChange($event,'doi')" (change)="checkBoxChange($event,'doi')" value="doi"
						[checked]="uncheck['doi']">
					<label class="form-check-label" for="check2">DOI</label>
				</div>
			</div>
		</div>
	</div>
	<!-- </form> -->
</form>