<!-- <div class="search mt-5">
    <div class="search-container">
        <h5 class="pb-3">Explore articles</h5>
        <app-search (searchEvent)="onSearchEvent($event)" (emitCheck)="emitSearch($event)"></app-search>
    </div>
</div> -->
<section class="section">
    <div  class="section-container"> 
        <h1>Explore chapters</h1>
        <app-search (searchEvent)="onSearchEvent($event)" (emitCheck)="emitSearch($event)" (searchClick)="showSearchList($event)"
        [search_filter]="search_filter" [search_value]="search_value" (clearEmit)="clearSearch($event)"></app-search>

        <div class="list-card">
            <div class="card chapter-card" *ngFor="let article of articles">
                <div class="card-body">
                    <a *ngIf="article?.article_title" class="search_link" [routerLink]="['/',article.article_category.heading.id,article.article_category.part.id, article.article_link]"><p class="ml-3 search-title" 
                        [innerHTML]="highlightMatch(article.article_title, search_value)">
                    </p></a>
                    <a *ngIf="article?.title" class="search_link" [routerLink]="['/page/',article.identifier]"><p class="ml-3 search-title" 
                        [innerHTML]="highlightMatch(article.title, search_value)">
                    </p></a>
                    <span *ngIf="article.authors" class="ml-3 search-author" [ngStyle]="{'font-size': '15px'}"
                    [innerHTML]="highlightMatch(getAuthorlist(article.authors), search_value)">
                    </span>
                    <p *ngIf="search_value !='' && article.article_content" class="ml-3 search-content" [ngStyle]="{'font-size': '15px'}"
                    [innerHTML]="article?.article_content | highlightSearch:search_value">
                    </p>
                    <p *ngIf="article?.content" class="ml-3 search-content" [ngStyle]="{'font-size': '15px'}"
                    [innerHTML]="article?.content | highlightSearch:search_value">
                    </p>
                    <p *ngIf="article.doi" class="ml-3 search-doi">
                        <!-- <fa-icon [icon]="['fas','calendar-days']"
                            class="icon-date pe-2"></fa-icon>
                        <span [innerHTML]="result.published_online"></span> -->
                        <fa-icon [icon]="['fas','earth-americas']"
                            class="icon-date pe-2"></fa-icon>
                        <a class="doi" href={{article.doi}} [innerHtml]="highlightMatch(article.doi, search_value)" target="_blank"></a>
                    </p>
                </div>
            </div>
        </div>
    </div>

</section>

<section class="contributors-container">
    <div class="contributors-section">
      
    </div>
</section>