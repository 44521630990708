import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleService } from '../../services/article.service';
import { SharedService } from '../../services/shared.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-chapter-search-list',
    templateUrl: './chapter-search-list.component.html',
    styleUrl: './chapter-search-list.component.scss'
})
export class ChapterSearchListComponent implements OnInit {
    search_filter: string[] = [];
    search_value:string = '';
    journalid:string = '';
    articles:any[] = [];
    value_to_search: string;
    constructor(
        private route:ActivatedRoute,
        private articleService:ArticleService,
        private sharedService:SharedService,
        private sanitizer: DomSanitizer
    ){}

    ngOnInit() {
        this.route.queryParams.subscribe((queryPara:any) => {
            // this.journalid = this.sharedService.getJournalId();
            this.search_value = queryPara['searchValue'] ?? '';
            this.search_filter = queryPara['filter']?.split(',') ?? [];
            this.sharedService.getJournal().subscribe((journalId) => {
                if (journalId) {
                    this.journalid = journalId;
    
                    // Now call listArticle() when journalId is available
                    const params: any = {
                        searchValue: this.search_value,
                        filters: this.search_filter.toString()
                    };
                    this.listArticle(params);
                }
            });
            // const params: any = {
            //     searchValue: this.search_value,
            //     filters: this.search_filter.toString()
            // };
            // this.listArticle(params);
        });
    }

    formatAuthors(authors: any[]): string {
        return authors.map(author => `${author.given_name} ${author.surname}`).join(', ');
    }

    onSearchEvent(searchValue: string) {
        // this.search_value = searchValue
        this.value_to_search = searchValue;
    }

    emitSearch(search_filter: string[]) {
        this.search_filter = search_filter
    }

    showSearchList(click: boolean) {
        this.search_value = this.value_to_search
        const params: any = {
            searchValue: this.search_value,
            filters: this.search_filter.toString()
        };
        this.listArticle(params);
    }

    highlightMatch(text: string, searchValue: string): SafeHtml {
        if (!text) {
            return this.sanitizer.bypassSecurityTrustHtml('');
        }
        if (!searchValue) {
            return this.sanitizer.bypassSecurityTrustHtml(text);
        }
        
        // Escape special characters in searchValue
        const escapedSearchValue = searchValue.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        
        const regex = new RegExp(escapedSearchValue, 'gi');
        const highlightedText = text.replace(regex, (match) => 
            `<span style="background-color: yellow;">${match}</span>`
        );
        
        return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
    }

    listArticle(params:any){
        if(this.journalid){
            this.articleService.getSearchListLepra(this.journalid, params).subscribe(result => {
                this.articles = [];
                this.articles = result.data;
            });
        }
    }

    clearSearch(clear:boolean){
        this.search_value = '';
        this.search_filter = [];
        const params: any = {
            searchValue: '',
            filters: ''
        };
        this.listArticle(params);
    }

    //for setting author
    getAuthorlist(authors: any[]) {
        const fullname1: string[] = [];
        const fullname2: string[] = [];

        if (authors) {
            authors.map((element) => {
                let detail = '';
                const spanStyle = "font-weight: bold;font-size:15px";
                detail =
                    "<span class='ref-pop-heading'><span class='tooltip-author'>" +
                    element.given_name +
                    ' ' +
                    element.surname;
                if (element.role) {
                    detail += ", " + element.role +
                        '</span></span>';
                }
                if (element.email) {
                    detail +=
                        "<div class='aff'>";
                    detail += "<span class='tooltip-email'>Author Email: </span>";
                    detail += '<p><a href="mailto:' + element.email + '">' + element.email + '</a></p>';
                    detail += '</div>';
                }
                if (element.affiliation) {
                    detail +=
                        "<div class='aff'>";
                    detail += "<span class='tooltip-aff'>Affiliation: </span>";
                    detail += "<p>" + element.affiliation + '</p>';
                    detail += '</div>';
                }
                let name = "<div class='tooltips affiliation-popover-content  p-0'>";

                name +=
                    "<span style='font-style:italic;font-size:15px'>" +
                    element.given_name +
                    ' ' +
                    element.surname +
                    "</span>" +
                    "<span class='tooltiptext' style='font-family:Roboto !important;font-size:15px'>" +
                    detail +
                    '</span></div></div>';
                fullname1.push(name);

                const sname = element.given_name + ' ' + element.surname;
                fullname2.push(sname);
            });
        }
        return fullname1.join(', ');
    }
}
